export const series = `
id
name
description
editors
image
creatorId
updatedAt
createdAt
`
