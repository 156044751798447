import Bag from '@/bags/Bag'

export default class User extends Bag<{
  selectedUser: surakh.gql.types.User | null
}> {
  state = this.getState({
    selectedUser: null,
  })

  async loadUser(uid: string) {
    this.state.selectedUser = null
    this.state.selectedUser = await this.$bags.queries.userByUid({ uid })
  }
}
