import { PluginObject } from 'vue'
import Axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const axios = Axios.create(config)

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  },
)

export { axios }

export const axiosPlugin: PluginObject<undefined> = {
  install: (Vue) => {
    Vue.axios = axios
    window.axios = axios
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return axios
        },
      },
      $axios: {
        get() {
          return axios
        },
      },
    })
  },
}
