import gql from 'graphql-tag'
import { QueryOptions } from './QueryOptions'
import * as fields from '@/schemas/fields'

export interface VariablesSeries {
  seriesId: string
}

export const series = new QueryOptions<VariablesSeries>(gql`
  query($seriesId: ID!) {
    series(seriesId: $seriesId) {
      ${fields.series}
    }
  }
`)
