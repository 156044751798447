import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesMessageTokenRemove {
  token: string
}

export const remove = (
  variables: VariablesMessageTokenRemove,
): MutationOptions<{ messageTokenRemove: boolean | null }, VariablesMessageTokenRemove> => {
  return {
    mutation: gql`
      mutation ($token: ID!) {
        messageTokenRemove(token: $token)
      }
    `,
    variables,
  }
}
