import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'
import { STORY_VISIBILITY } from 'common/story/visibility'

export interface VariablesUpdate {
  storyId: string
  title: string
  content: string
  visibility: STORY_VISIBILITY
  tags: string[]
  seriesId: string | null
}

export const update = (
  variables: VariablesUpdate,
): MutationOptions<{ storyUpdate?: surakh.gql.types.Story }, VariablesUpdate> => {
  return {
    mutation: gql`
      mutation(
        $storyId: ID!
        $title: String!
        $content: String!
        $visibility: GraphEnumStoryVisibility!
        $tags: [String]!
        $seriesId: String
      ) {
        storyUpdate(
          storyId: $storyId
          title: $title
          content: $content
          visibility: $visibility
          tags: $tags
          seriesId: $seriesId
        ) {
          ${fields.story()}
        }
      }
    `,
    variables,
  }
}
