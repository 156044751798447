import { Component, Vue } from 'vue-property-decorator'
import * as schemas from '@/schemas'

@Component<MixinPageStory>({
  apollo: {
    story: {
      query: schemas.queries.story.queryOptions.query,
      variables() {
        return { storyId: this.$route.params.storyId }
      },
    },
  },
})
export class MixinPageStory extends Vue {
  story!: surakh.gql.types.Story

  get images() {
    return this.story?.images ?? []
  }

  get loadingStory() {
    return this.$apollo.queries.story.loading
  }
}
