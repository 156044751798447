import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export const create: MutationOptions<{ storyCreate: string }> = {
  mutation: gql`
    mutation {
      storyCreate
    }
  `,
}
