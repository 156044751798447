export default class ExecuteAfter {
  timer: unknown = undefined

  call(
    func: () => Promise<unknown> | unknown,
    milliseconds = 3000,
    on: (status: 0 | 1 | 2) => void = (_) => {
      /* */
    },
  ) {
    on(0)
    clearTimeout(this.timer as number | undefined)
    this.timer = setTimeout(async () => {
      on(1)
      await func()
      on(2)
    }, milliseconds)
  }
}
