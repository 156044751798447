import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  CodeBlockHighlight,
  HardBreak,
  Heading,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Placeholder,
  Table,
  History,
  TableCell,
  TableHeader,
  TableRow,
  Underline,
  TrailingNode,
} from 'tiptap-extensions'
import languages from './highlightLanguages'
import Image from './Image'
import Video from './Video'
import Embed from './Embed'
import Markdown from './Markdown'
import Images from './Images'

export default [
  new Blockquote(),
  new CodeBlockHighlight({ languages }),
  new Code(),
  new HardBreak(),
  new Heading({ levels: [1, 2, 3] }),
  new BulletList(),
  new OrderedList(),
  new ListItem(),
  new Bold(),
  new Code(),
  new History(),
  new Italic(),
  new Link(),
  new Underline(),
  new Table({ resizable: true }),
  new TableHeader(),
  new TableCell(),
  new TableRow(),
  new HorizontalRule(),
  new Placeholder({
    emptyEditorClass: 'is-editor-empty',
    emptyNodeClass: 'is-empty',
    emptyNodeText: 'бичих …',
    showOnlyWhenEditable: true,
    showOnlyCurrent: true,
  }),
  new HorizontalRule(),
  new TrailingNode({
    node: 'paragraph',
    notAfter: ['paragraph'],
  }),
  new Image(),
  new Video(),
  new Embed(),
  new Markdown(),
  new Images(),
]
