import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'
import { InterfaceSeries } from '@/types'

export interface VariablesUpdate {
  seriesId: string
  name?: string | null
  description?: string | null
  editors?: string[] | null
  image?: string | null
}

export const update = (
  variables: VariablesUpdate,
): MutationOptions<{ seriesUpdate: InterfaceSeries | null }, VariablesUpdate> => {
  return {
    mutation: gql`
      mutation($seriesId: ID!, $name: String!, $description: String!, $editors: [String!], $image: String) {
        seriesUpdate(seriesId: $seriesId, name: $name, description: $description, editors: $editors, image: $image) {
          ${fields.series}
        }
      }
    `,
    variables,
  }
}
