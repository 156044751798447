import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

@Component({
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    value: [String, Number, Boolean, Object, Array],
  },
})
export class MixinEditor extends Vue {
  public readonly editable!: boolean
  public readonly value!: string
  public content: string | null = null
  public firstContent: string | null = null

  public input(event: Event) {
    const text = (event.target! as HTMLElement).innerText
    this.content = text
    this.$emit('input', this.content)
    this.$emit('change')
  }

  @Watch('value', { deep: true })
  onValueChange() {
    // update first content when parent value and content is not same
    if (JSON.stringify(this.value) !== JSON.stringify(this.content)) {
      this.content = this.value
      this.firstContent = this.value
    }
  }

  created() {
    this.content = this.value
    this.firstContent = this.value
  }
}
