import { InterfaceUnsplashSearchResponse } from '../types/unsplash'
import { InterfaceStories, InterfaceSeries } from '@/types'
import Bag from '@/bags/Bag'
import * as schemas from '@/schemas'

export default class Queries extends Bag {
  async userByUid(
    variables: surakh.Schema.User.VariablesUserByUid,
  ): Promise<surakh.gql.types.User | null> {
    const response = await this.$query(schemas.queries.userByUid.getOptions(variables))
    return response.data.userByUid as surakh.gql.types.User | null
  }

  async userByEmail(
    variables: surakh.Schema.User.VariablesUserByEmail,
  ): Promise<surakh.gql.types.User | null> {
    const response = await this.$query(schemas.queries.userByEmail.getOptions(variables))
    return response.data.userByEmail as surakh.gql.types.User | null
  }

  async stories(variables: schemas.queries.VariablesStories): Promise<InterfaceStories> {
    const response = await this.$query(schemas.queries.stories.getQueryOptions(variables))
    return response.data.stories as InterfaceStories
  }

  async story(variables: schemas.queries.VariablesStory): Promise<surakh.gql.types.Story | null> {
    const response = await this.$query(schemas.queries.story.getQueryOptions(variables))
    return response.data.story
  }

  async unsplashImages(
    variables: schemas.queries.VariablesUnsplashImages,
  ): Promise<InterfaceUnsplashSearchResponse> {
    const response = await this.$query(schemas.queries.unsplashImages(variables))
    return response.data?.unsplashImages as InterfaceUnsplashSearchResponse
  }

  async myInfo(refetch = false) {
    const response = await this.$query(
      {
        ...schemas.queries.myInfo,
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      },
      false,
    )
    return response.data.myInfo
  }

  async myPinnedStories() {
    const response = await this.$query(schemas.queries.myPinnedStories)
    return response.data?.myPinnedStories ?? []
  }

  async myRecentStories() {
    const response = await this.$query(schemas.queries.myRecentStories)
    return response.data?.myRecentStories ?? []
  }

  async mySeries(): Promise<InterfaceSeries[]> {
    const response = await this.$query(schemas.queries.mySeries)
    return (response.data?.mySeries ?? []) as InterfaceSeries[]
  }

  async series(variables: schemas.queries.VariablesSeries): Promise<InterfaceSeries[]> {
    const response = await this.$query(schemas.queries.series.getOptions(variables))
    return (response.data?.series ?? []) as InterfaceSeries[]
  }

  async userSeries(variables: surakh.Schema.User.VariablesUserSeries): Promise<InterfaceSeries[]> {
    const response = await this.$query(schemas.queries.userSeries.getOptions(variables))
    return (response.data?.userSeries ?? []) as InterfaceSeries[]
  }

  async myRecentTags(variables: surakh.gql.query.variables.MyRecentTags): Promise<string[]> {
    const response = await this.$query(schemas.queries.myRecentTags(variables))
    return (response.data.myRecentTags ?? []) as string[]
  }

  async storyComments(
    variables: schemas.queries.VariablesStoryComments,
  ): Promise<surakh.gql.types.StoryComment[]> {
    const response = await this.$query(schemas.queries.storyComments(variables))
    return (response.data?.storyComments ?? []) as surakh.gql.types.StoryComment[]
  }

  async popularTags(): Promise<string[]> {
    const response = await this.$query(schemas.queries.popularTags)
    return (response.data.popularTags ?? []) as string[]
  }
}
