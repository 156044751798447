import gql from 'graphql-tag'
import { QueryOptions } from 'apollo-client'
import * as fields from '@/schemas/fields'

export interface VariablesStoryComments {
  storyId: string
}

export const storyComments = (
  variables: VariablesStoryComments,
): QueryOptions<VariablesStoryComments> => {
  return {
    query: gql`
      query($storyId: ID!) {
        storyComments(storyId: $storyId) {
          ${fields.storyComment}
        }
      }
    `,
    variables,
  }
}
