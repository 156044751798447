import { NavigationGuard } from 'vue-router'
import bags from '@/bags'

const myStory: NavigationGuard = async (to, _from, next) => {
  const storyId = to.params.storyId
  let story: surakh.gql.types.Story | null = null
  try {
    story = await bags.queries.story({ storyId })
  } catch {
    //
  }

  if (story) {
    return next()
  }

  return next(new Error('not found'))
}

export default myStory
