import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'
import * as image from './image'
import * as story from './story'
import * as series from './series'
import * as messageToken from './messageToken'

export { story, image, series, messageToken }

export const userUpdate: surakh.gql.mutation.option.UserUpdate = (variables) => {
  return {
    mutation: gql`
      mutation ($bio: String, $displayName: String, $photoURL: String, $language: String, $didAcceptTerms: Boolean) {
        userUpdate(bio: $bio, displayName: $displayName, photoURL: $photoURL, language: $language, didAcceptTerms: $didAcceptTerms) {
          ${fields.myInfo}
        }
      }
    `,
    variables,
  }
}

export const userCreate: surakh.gql.mutation.option.UserCreate = (variables) => {
  return {
    mutation: gql`
      mutation ($bio: String!, $displayName: String!, $photoURL: String, $didAcceptTerms: Boolean!) {
        userCreate(bio: $bio, displayName: $displayName, photoURL: $photoURL, didAcceptTerms: $didAcceptTerms) {
          ${fields.myInfo}
        }
      }
    `,
    variables,
  }
}

export const userRemoveRecentStory: surakh.gql.mutation.option.UserRemoveRecentStory = (
  variables,
) => {
  return {
    mutation: gql`
      mutation ($storyId: ID!) {
        userRemoveRecentStory(storyId: $storyId)
      }
    `,
    variables,
  }
}

export const unsplashDownload: surakh.gql.mutation.option.UnsplashDownload = (variables) => {
  return {
    mutation: gql`
      mutation ($downloadLocation: String!) {
        unsplashDownload(downloadLocation: $downloadLocation)
      }
    `,
    variables,
  }
}
