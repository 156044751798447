enum EnumCommand {
  blockQuote = 'blockquote',
  bold = 'bold',
  bulletList = 'bullet_list',
  code = 'code',
  codeBlock = 'code_block',
  heading = 'heading',
  image = 'image',
  images = 'images',
  italic = 'italic',
  link = 'link',
  markdown = 'markdown',
  embed = 'embed',
  orderedList = 'ordered_list',
  video = 'video',
  underline = 'underline',
  strikethrough = 'strike',
  createTable = 'createTable',
  deleteTable = 'deleteTable',
  addColumnBefore = 'addColumnBefore',
  addColumnAfter = 'addColumnAfter',
  deleteColumn = 'deleteColumn',
  addRowBefore = 'addRowBefore',
  addRowAfter = 'addRowAfter',
  deleteRow = 'deleteRow',
  toggleCellMerge = 'toggleCellMerge',
}

export default EnumCommand
