import router from '@/plugins/router'
import storeOptions from '@/store'
import Vue from 'vue'
import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'

Vue.use(Vuex)

const store = new Vuex.Store(storeOptions)

sync(store, router)

export default store
