import Cookies from 'js-cookie'

let cookies: Record<string, unknown> = {}
try {
  const parsedCookies = JSON.parse(Cookies.get('__session') ?? '{}')
  if (parsedCookies && typeof parsedCookies === 'object') {
    cookies = parsedCookies
  }
} catch {
  // do nothing
}

const secure = process.env.NODE_ENV === 'production'

export default {
  get(name: string): unknown {
    return cookies[name]
  },
  set(name: string, value?: string | boolean | number | undefined | null): void {
    if (value === undefined || value === null) {
      delete cookies[name]
    } else {
      cookies[name] = value
    }
    Cookies.set('__session', cookies, {
      path: '/',
      sameSite: 'lax',
      domain: process.env.VUE_APP_DOMAIN,
      secure,
    })
  },
  remove(name: string): void {
    delete cookies[name]
    Cookies.set('__session', cookies, {
      path: '/',
      sameSite: 'lax',
      domain: process.env.VUE_APP_DOMAIN,
      secure,
    })
  },
}
