import { Node } from 'tiptap'
import { TiptapMarkdown } from '../components'
import TiptapNode from './Node'

interface Attributes {
  text: string | null
}

export default class Markdown extends TiptapNode {
  public get name() {
    return 'markdown'
  }

  public get view() {
    return TiptapMarkdown
  }

  public get schema() {
    return {
      attrs: {
        text: {
          default: null,
        },
      },
      defining: true,
      group: 'block',
      selectable: false,
      draggable: false,
      parseDOM: [
        {
          tag: '[data-type="markdown"]',
          getAttrs: (dom: Element): Attributes => ({
            text: dom.getAttribute('data-content') ?? null,
          }),
        },
      ],
      toDOM: (node: Node) => ['div', { 'data-type': 'markdown', 'data-content': node.attrs.text }],
    }
  }
}
