import Default from './Default'
import My from './My'
import Theme from './Theme'
import Queries from './Queries'
import Mutations from './Mutations'
import Tiptap from './Tiptap'
import User from './User'

export default {
  default: new Default(),
  my: new My(),
  theme: new Theme(),
  queries: new Queries(),
  mutations: new Mutations(),
  tiptap: new Tiptap(),
  user: new User(),
}
