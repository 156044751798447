import routes from '../router'
import VueRouter from 'vue-router'
import Vue from 'vue'
import consola from 'consola'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (to.name === 'runantsrun-charity2020') {
      return
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.onError((error) => {
  consola.error(error.message)
  router.replace('/500')
})

export default router
