enum EnumMenu {
  headingMedium = 'headingMedium',
  heading2 = 'heading2',
  heading3 = 'heading3',
  bold = 'bold',
  italic = 'italic',
  strikethrough = 'strikethrough',
  underline = 'underline',
  code = 'code',
  codeBlock = 'codeBlock',
  link = 'link',
  bulletList = 'bulletList',
  orderedList = 'orderedList',
  blockQuote = 'blockQuote',
  image = 'image',
  images = 'images',
  video = 'video',
  markdown = 'markdown',
  embed = 'embed',
  createTable = 'createTable',
  deleteTable = 'deleteTable',
  addColumnBefore = 'addColumnBefore',
  addColumnAfter = 'addColumnAfter',
  deleteColumn = 'deleteColumn',
  addRowBefore = 'addRowBefore',
  addRowAfter = 'addRowAfter',
  deleteRow = 'deleteRow',
  toggleCellMerge = 'toggleCellMerge',
}

export default EnumMenu
