import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'

export interface VariablesPin {
  storyId: string
  pin: boolean
}

export const pin = (
  variables: VariablesPin,
): MutationOptions<{ storyPin: surakh.gql.types.Story | null }, VariablesPin> => {
  return {
    mutation: gql`
      mutation($storyId: ID!, $pin: Boolean!) {
        storyPin(storyId: $storyId, pin: $pin) {
          ${fields.story()}
        }
      }
    `,
    variables,
  }
}
