import loadImage from 'blueimp-load-image'

export default class ImageLoader {
  private static getCanvasImage(image: string | File | Blob): Promise<HTMLCanvasElement> {
    return new Promise((resolve) => {
      loadImage.parseMetaData(image, (data) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const options: any = {
          maxHeight: 800,
          maxWidth: 800,
          canvas: true,
        }
        const exif = (data as { exif?: { get: (property: string) => boolean } }).exif
        if (exif) {
          options.orientation = exif.get('Orientation')
        }
        loadImage(image, (image) => resolve(image as HTMLCanvasElement), options)
      })
    })
  }

  static async getDataURL(image: string | File | Blob) {
    const canvasImage = await this.getCanvasImage(image)
    return canvasImage.toDataURL()
  }

  static getDataURLs(images: (string | File | Blob)[]) {
    return Promise.all(
      images.map((image) => {
        if (typeof image === 'string') {
          return image
        }
        return this.getDataURL(image)
      }),
    )
  }

  private static getImageFilesFromDragEvent(event: DragEvent, max = 5) {
    const files = Array.from(event.dataTransfer?.files ?? [])
    const imageFiles = files.filter((file) => /image/i.test(file.type)).slice(0, max)
    if (imageFiles.length > 0) {
      return imageFiles
    }
    const image = event.dataTransfer?.getData('text/html') ?? ''
    const element = document.createElement('div')
    element.innerHTML = image
    element.append(image)
    const src = element.querySelector('img')?.src
    if (src) {
      return [src]
    }
    return []
  }

  static getDataURLsFromDragEvent(event: DragEvent, max = 5) {
    const images = this.getImageFilesFromDragEvent(event, max)
    return this.getDataURLs(images)
  }

  static getDataURLFromDragEvent(event: DragEvent) {
    const file = this.getImageFilesFromDragEvent(event, 1)[0]
    if (typeof file === 'string') {
      return file
    }
    if (file) {
      return this.getDataURL(file)
    }
    return null
  }

  private static getImageFilesFromEvent(event: Event, max = 5) {
    const files = Array.from((event.target as HTMLInputElement)?.files ?? [])
    return files.filter((file) => /image/i.test(file.type)).slice(0, max)
  }

  static getDataURLFromEvent(event: Event) {
    const file = this.getImageFilesFromEvent(event, 1)[0]
    if (file) {
      return this.getDataURL(file)
    }
    return null
  }

  static getDataURLsFromEvent(event: Event, max = 5) {
    return this.getDataURLs(this.getImageFilesFromEvent(event, max))
  }
}
