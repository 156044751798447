import consola from 'consola'
import Bag from '@/bags/Bag'
import * as schemas from '@/schemas'
import { InterfaceSeries } from '@/types'
import gql from 'graphql-tag'

export default class Mutations extends Bag {
  async storyCreate() {
    const response = await this.$mutate({
      ...schemas.mutations.story.create,
    })
    this.$apollo.store.reset()
    return response.data?.storyCreate ?? null
  }

  async storyLike(variables: schemas.mutations.story.VariablesLike) {
    const response = await this.$mutate({
      ...schemas.mutations.story.like(variables),
      update(data, result) {
        const query = schemas.queries.story.getQueryOptions(variables)
        data.writeQuery({
          ...query,
          data: { story: result.data?.storyLike },
        })
      },
    })
    return response.data?.storyLike ?? null
  }

  async storyPin(variables: schemas.mutations.story.VariablesPin) {
    const response = await this.$mutate({
      ...schemas.mutations.story.pin(variables),
      update(data, result) {
        const query = schemas.queries.story.getQueryOptions(variables)
        data.writeQuery({
          ...query,
          data: { story: result.data?.storyPin },
        })

        try {
          const { storyId } = variables
          const pinsQuery = schemas.queries.myPinnedStories
          const pinsData = data.readQuery<{ myPinnedStories: string[] }>(pinsQuery)
          if (pinsData) {
            const index = pinsData.myPinnedStories.indexOf(storyId)
            if (index > -1) {
              pinsData.myPinnedStories.splice(index, 1)
            }
            if (variables.pin && result.data?.storyPin) {
              pinsData.myPinnedStories.splice(0, 0, storyId)
            }
            data.writeQuery({ ...pinsQuery, data: pinsData })
          }
        } catch (error) {
          consola.warn('myPinnedStories')
        }
      },
    })
    return response.data?.storyPin ?? null
  }

  async storyView(variables: schemas.mutations.story.VariablesView, updateMyRecentStories = true) {
    const response = await this.$mutate({
      ...schemas.mutations.story.view(variables),
      update(data, result) {
        const query = schemas.queries.story.getQueryOptions(variables)
        const story = data.readQuery<{ story: surakh.gql.types.Story }>(query)
        data.writeQuery({
          ...query,
          data: { story: { ...story?.story, views: result.data?.storyView } },
        })

        if (!updateMyRecentStories) {
          return
        }

        try {
          const { storyId } = variables
          const recentsQuery = schemas.queries.myRecentStories
          const recentsData = data.readQuery<{ myRecentStories: string[] }>(recentsQuery)
          if (recentsData && storyId) {
            const index = recentsData.myRecentStories.indexOf(storyId)
            if (index > -1) {
              recentsData.myRecentStories.splice(index, 1)
            }
            recentsData.myRecentStories.splice(0, 0, storyId)
            data.writeQuery({ ...recentsQuery, data: recentsData })
          }
        } catch {
          //
        }
      },
    })
    return response.data?.storyView ?? null
  }

  async storyUpdate(variables: schemas.mutations.story.VariablesUpdate) {
    const response = await this.$mutate({
      ...schemas.mutations.story.update(variables),
      update(data, result) {
        const story: surakh.gql.types.Story | undefined = result.data?.storyUpdate
        if (!story) {
          return
        }

        const storyQuery = schemas.queries.story.getQueryOptions(variables)
        data.writeQuery({
          ...storyQuery,
          data: { story: result.data?.storyUpdate },
        })
      },
    })
    this.$apollo.store.reset()
    return response.data?.storyUpdate ?? null
  }

  async storyRemove(
    variables: schemas.mutations.story.VariablesRemove,
    beforeHandler?: () => void,
  ) {
    const response = await this.$mutate(schemas.mutations.story.remove(variables))
    this.userRemoveRecentStory(variables)
    if (beforeHandler) {
      beforeHandler()
    }
    this.$apollo.store.reset()
    return response.data?.storyRemove ?? null
  }

  async storyImageAdd(variables: { storyId?: string; image: string }) {
    const _variables = { storyId: Bag.store.route.params.storyId, ...variables }

    const response = await this.$mutate<
      { storyImageAdd: string },
      { storyId: string; image: string }
    >({
      mutation: gql`
        mutation ($storyId: ID!, $image: String!) {
          storyImageAdd(storyId: $storyId, image: $image)
        }
      `,
      variables: _variables,
      update(proxy, result) {
        const query = schemas.queries.story.getQueryOptions(_variables)
        const data = proxy.readQuery<{ story: surakh.gql.types.Story }>(query)
        if (data?.story && result.data?.storyImageAdd) {
          const images = data.story.images ?? []
          if (!/^https:\/\/images\.unsplash\.com\//.test(result.data.storyImageAdd)) {
            images.push(result.data.storyImageAdd)
            data.story.images = images
            proxy.writeQuery({ ...query, data })
          }
        }
      },
    })
    return response.data?.storyImageAdd
  }

  async storyImageRemove(variables: { storyId?: string; image: string }) {
    const _variables = { storyId: Bag.store.route.params.storyId, ...variables }

    const response = await this.$mutate<
      { storyImageRemove: string },
      { storyId: string; image: string }
    >({
      mutation: gql`
        mutation ($storyId: ID!, $image: String!) {
          storyImageRemove(storyId: $storyId, image: $image)
        }
      `,
      variables: _variables,
      update: (proxy, result) => {
        const query = schemas.queries.story.getQueryOptions(_variables)
        const data = proxy.readQuery<{ story: surakh.gql.types.Story }>(query)
        if (data?.story.images && result.data?.storyImageRemove) {
          const index = data.story.images.indexOf(result.data.storyImageRemove)
          if (index > -1) {
            data.story.images.splice(index, 1)
          }
        }
        proxy.writeQuery({ ...query, data })
      },
    })
    return response.data?.storyImageRemove
  }

  async userUpdate(variables: surakh.gql.mutation.variables.UserUpdate) {
    const response = await this.$mutate({
      ...schemas.mutations.userUpdate(variables),
      update(store, result) {
        store.writeQuery({
          ...schemas.queries.myInfo,
          data: { myInfo: result.data?.userUpdate },
        })
        store.writeQuery({
          ...schemas.queries.userByUid.getOptions({ uid: Bag.bags.my.getter.uid! }),
          data: { userByUid: result.data?.userUpdate },
        })
      },
    })
    return response.data?.userUpdate
  }

  async userCreate(variables: surakh.gql.mutation.variables.UserCreate) {
    const response = await this.$mutate({
      ...schemas.mutations.userCreate(variables),
      update(store, result) {
        store.writeQuery({
          ...schemas.queries.myInfo,
          data: { myInfo: result.data?.userCreate },
        })
        store.writeQuery({
          ...schemas.queries.userByUid.getOptions({ uid: Bag.bags.my.getter.uid! }),
          data: { userByUid: result.data?.userCreate },
        })
      },
    })
    return response.data?.userCreate ?? null
  }

  async userRemoveRecentStory(variables: surakh.gql.mutation.variables.UserRemoveRecentStory) {
    const response = await this.$mutate({
      ...schemas.mutations.userRemoveRecentStory(variables),
      update(store, result) {
        try {
          const data = store.readQuery<{ myRecentStories: string[] }>(
            schemas.queries.myRecentStories,
          )
          if (data && result.data?.userRemoveRecentStory) {
            const index = data.myRecentStories.indexOf(result.data.userRemoveRecentStory)
            if (index > -1) {
              data.myRecentStories.splice(index, 1)
              store.writeQuery({ ...schemas.queries.myRecentStories, data })
            }
          }
        } catch (error) {
          consola.warn('mutation/userRemoveRecentStory')
        }
      },
    })
    return response.data?.userRemoveRecentStory
  }

  async storyCommentCreate(variables: schemas.mutations.story.comment.VariablesStoryCommentCreate) {
    const response = await this.$mutate({
      ...schemas.mutations.story.comment.create(variables),
      update(store, result) {
        try {
          const data = store.readQuery<{ storyComments: surakh.gql.types.StoryComment[] }>(
            schemas.queries.storyComments(variables),
          )
          if (data?.storyComments && result.data) {
            data.storyComments.splice(0, 0, result.data.storyCommentCreate)
          }
          store.writeQuery({
            ...schemas.queries.storyComments(variables),
            data,
          })
        } catch (error) {
          consola.warn('storyComments')
        }
      },
    })
    return response.data?.storyCommentCreate
  }

  async storyCommentUpdate(variables: schemas.mutations.story.comment.VariablesStoryCommentUpdate) {
    const response = await this.$mutate({
      ...schemas.mutations.story.comment.update(variables),
      update(store, result) {
        try {
          const data = store.readQuery<{ storyComments: surakh.gql.types.StoryComment[] }>(
            schemas.queries.storyComments(variables),
          )
          if (data?.storyComments && result.data) {
            const index = data.storyComments.findIndex(
              (comment) => comment.id === result.data!.storyCommentUpdate.id,
            )
            if (index > -1) {
              data.storyComments.splice(index, 1, result.data.storyCommentUpdate)
            }
          }
          store.writeQuery({
            ...schemas.queries.storyComments(variables),
            data,
          })
        } catch (error) {
          consola.warn('storyComments')
        }
      },
    })
    return response.data?.storyCommentUpdate
  }

  async storyCommentRemove(variables: schemas.mutations.story.comment.VariablesStoryCommentRemove) {
    const response = await this.$mutate({
      ...schemas.mutations.story.comment.remove(variables),
      update(store, result) {
        try {
          const data = store.readQuery<{ storyComments: surakh.gql.types.StoryComment[] }>(
            schemas.queries.storyComments(variables),
          )
          if (data?.storyComments && result.data) {
            const index = data.storyComments.findIndex(
              (comment) => comment.id === variables.commentId,
            )
            if (index > -1) {
              data.storyComments.splice(index, 1)
            }
          }
          store.writeQuery({
            ...schemas.queries.storyComments(variables),
            data,
          })
        } catch (error) {
          consola.warn('storyComments')
        }
      },
    })
    return response.data?.storyCommentRemove
  }

  async seriesCreate(variables: schemas.mutations.series.VariablesCreate) {
    const response = await this.$mutate({
      ...schemas.mutations.series.create(variables),
      update(store, result) {
        try {
          const query = schemas.queries.userSeries.getOptions({ uid: Bag.bags.my.getter.uid! })
          const data = store.readQuery<{ userSeries: InterfaceSeries[] }>(query)
          if (data?.userSeries && result.data?.seriesCreate) {
            data.userSeries.splice(0, 0, result.data.seriesCreate)
            store.writeQuery({
              ...query,
              data,
            })
          }
        } catch (error) {
          consola.warn('mutations/seriesCreate')
        }
      },
    })
    return response.data?.seriesCreate
  }

  async seriesUpdate(variables: schemas.mutations.series.VariablesUpdate) {
    const response = await this.$mutate({
      ...schemas.mutations.series.update(variables),
      update(store, result) {
        try {
          const query = schemas.queries.userSeries.getOptions({ uid: Bag.bags.my.getter.uid! })
          const data = store.readQuery<{ userSeries: InterfaceSeries[] }>(query)
          if (data?.userSeries && result.data?.seriesUpdate) {
            const index = data.userSeries.findIndex(
              (series) => series.id === result.data?.seriesUpdate?.id,
            )
            if (index > -1) {
              data.userSeries.splice(index, 1, result.data.seriesUpdate)
              store.writeQuery({
                ...query,
                data,
              })
            }
          }
        } catch (error) {
          consola.warn('mutations/seriesUpdate')
        }
      },
    })
    return response.data
  }

  async seriesRemove(variables: schemas.mutations.series.VariablesRemove) {
    const response = await this.$mutate({
      ...schemas.mutations.series.remove(variables),
      update(store, result) {
        try {
          const query = schemas.queries.userSeries.getOptions({ uid: Bag.bags.my.getter.uid! })
          const data = store.readQuery<{ userSeries: InterfaceSeries[] }>(query)
          if (data?.userSeries && result.data?.seriesRemove) {
            const index = data.userSeries.findIndex((series) => series.id === variables.seriesId)
            if (index > -1) {
              data.userSeries.splice(index, 1)
              store.writeQuery({
                ...query,
                data,
              })
            }
          }
        } catch (error) {
          consola.warn('mutations/seriesRemove')
        }
      },
    })
    return response.data?.seriesRemove
  }

  async unsplashDownload(variables: surakh.gql.mutation.variables.UnsplashDownload) {
    const response = await this.$mutate(schemas.mutations.unsplashDownload(variables))
    return response.data?.unsplashDownload
  }

  messageTokenSet(variables: schemas.mutations.messageToken.VariablesMessageTokenSet) {
    return this.$mutate(schemas.mutations.messageToken.set(variables))
  }

  messageTokenRemove(variables: schemas.mutations.messageToken.VariablesMessageTokenRemove) {
    return this.$mutate(schemas.mutations.messageToken.remove(variables))
  }
}
