export const myInfo = `
id
bio
email
emailVerified
createdAt
displayName
isAdmin
photoURL
uid
updatedAt
providerIds
didAcceptTerms
language
`
