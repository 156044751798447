// import 'vuetify/dist/vuetify.min.css'
// import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify'
import { colors } from 'vuetify/lib'
import { en, ja } from 'vuetify/src/locale'

export const vuetifyPlugin = Vuetify

export const vuetify = new Vuetify({
  options: {
    customProperties: true,
  },
  lang: {
    locales: { ja, mn: { ...en }, en },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken4,
        secondary: colors.grey.darken2,
        accent: colors.pink.base,
        anchor: colors.blueGrey.darken4,
      },
      dark: {
        primary: colors.shades.black,
        secondary: colors.amber.darken3,
        accent: colors.pink.base,
        anchor: colors.shades.white,
      },
    },
  },
})
