import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesView {
  storyId: string
}

export const view = (
  variables: VariablesView,
): MutationOptions<{ storyView?: number }, VariablesView> => {
  return {
    mutation: gql`
      mutation ($storyId: ID!) {
        storyView(storyId: $storyId)
      }
    `,
    variables,
  }
}
