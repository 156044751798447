import 'firebase/messaging'
import 'firebase/auth'
import 'firebase/analytics'

import FirebaseApp from 'firebase/app'
import { PluginObject } from 'vue'

export class Firebase {
  public readonly auth: FirebaseApp.auth.Auth
  public readonly app: FirebaseApp.app.App

  public constructor(config: Record<string, string | number>) {
    this.app = FirebaseApp.initializeApp(config)
    this.app.analytics()
    this.auth = this.app.auth()
  }
}

export const firebase = new Firebase({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
})

export const firebasePlugin: PluginObject<undefined> = {
  install: (Vue) => {
    const auth = firebase.auth
    Vue.firebase = firebase.app
    Vue.auth = auth
    Vue.prototype.$firebase = firebase
    Vue.prototype.$auth = auth
  },
}
