import VueApollo from 'vue-apollo'
import { apolloClient } from '@/lib'

// Install the vue plugin
export const apolloPlugin = VueApollo

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError',
      'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
      error.message,
    )
  },
})
