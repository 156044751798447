import { Node } from 'tiptap'
import { TiptapEmbed } from '../components'
import TiptapNode from './Node'

export default class Embed extends TiptapNode {
  public get name() {
    return 'embed'
  }

  public get view() {
    return TiptapEmbed
  }

  public get schema() {
    return {
      attrs: {
        content: {
          default: null,
        },
      },
      defining: true,
      group: 'block',
      selectable: false,
      draggable: false,
      parseDOM: [
        {
          tag: '[data-type="editorembed"]',
          getAttrs: (dom: Element): { content: string | null } => ({
            content: dom.getAttribute('data-content'),
          }),
        },
      ],
      toDOM: (node: Node) => [
        'div',
        { 'data-type': 'editorembed', 'data-content': node.attrs.content },
      ],
    }
  }
}
