import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesMessageTokenSet {
  token: string
  uid?: string | null
}

export const set = (
  variables: VariablesMessageTokenSet,
): MutationOptions<{ messageTokenSet: boolean | null }, VariablesMessageTokenSet> => {
  return {
    mutation: gql`
      mutation ($token: ID!, $uid: ID) {
        messageTokenSet(token: $token, uid: $uid)
      }
    `,
    variables,
  }
}
