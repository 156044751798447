import passwordValidator from 'common/passwordValidator'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class MixinFormRules extends Vue {
  rules = {
    notEmpty: [(v: string | null) => !!v || 'хоосон байж болохгүй'],
    checkbox: [(v: boolean) => v || 'must be checked'],
    email: [
      (v: string | null) =>
        (!!v && /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(v)) ||
        this.$t('invalidEmail'),
    ],
    bio: [
      (v: string | null) =>
        (!!v && v.length <= 160) || this.$t('maxLength', { name: this.$t('bio'), length: 160 }),
    ],
    displayName: [
      (v: string | null) => !!v || this.$t('notEmpty', { name: this.$t('displayName') }),
      (v: string | null) =>
        (!!v && v.length >= 3) || this.$t('minLength', { name: this.$t('displayName'), length: 3 }),
      (v: string | null) =>
        (!!v && v.length <= 25) ||
        this.$t('maxLength', { name: this.$t('displayName'), length: 25 }),
    ],
    password: [
      (v: string | null) => !!v || this.$t('notEmpty', { name: this.$t('password') }),
      (v: string | null) =>
        (!!v && v.length >= 8) || this.$t('minLength', { name: this.$t('password'), length: 8 }),
      (v: string | null) =>
        (!!v && v.length <= 16) || this.$t('maxLength', { name: this.$t('password'), length: 16 }),
      (v: string | null) => {
        if (v) {
          const test = passwordValidator.validate(v)
          return test || 'Password must have at least one uppercase, lowercase and number'
        }
        return ''
      },
    ],
    passwordCheck: [
      (v: string | null) => !!v || this.$t('notEmpty', { name: this.$t('password') }),
      (v: string | null) =>
        (!!v && v.length >= 8) || this.$t('minLength', { name: this.$t('password'), length: 8 }),
      (v: string | null) =>
        (!!v && v.length <= 16) || this.$t('maxLength', { name: this.$t('password'), length: 16 }),
      (v: string | null) => {
        if (v) {
          const test = passwordValidator.validate(v)
          return test || 'Password must have at least one uppercase, lowercase and number'
        }
        return ''
      },
    ],
  }
}
