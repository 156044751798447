import Bag from './Bag'

export default class Theme extends Bag<{
  dark: boolean
}> {
  state = this.getState({
    get dark(): boolean {
      return !!localStorage.getItem('isDarkTheme')
    },
    set dark(value) {
      if (value) {
        localStorage.setItem('isDarkTheme', 'true')
      } else {
        localStorage.removeItem('isDarkTheme')
      }
    },
  })

  toggle(value?: boolean) {
    this.state.dark = value === undefined ? !this.state.dark : value
  }
}
