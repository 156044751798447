import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesRemove {
  storyId: string
}

export const remove = (
  variables: VariablesRemove,
): MutationOptions<{ storyRemove?: string }, VariablesRemove> => {
  return {
    mutation: gql`
      mutation ($storyId: ID!) {
        storyRemove(storyId: $storyId)
      }
    `,
    variables,
  }
}
