import { Editor } from 'tiptap'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import command from './command'

@Component({
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    editor: {
      type: Object,
      required: true,
    },
  },
})
export default class MixinEditorMenu extends Vue {
  public readonly menus!: command[]
  public readonly editor!: Editor

  public get hasMenu() {
    return this.menus.length > 0
  }

  public isMainMenu = true

  public reset() {
    this.isMainMenu = true
  }
}
