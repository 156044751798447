import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component<MixinTiptapEditor>({
  props: {
    node: Object,
    updateAttrs: Function,
    editable: Boolean,
    options: Object,
    selected: Boolean,
    view: Object,
  },
})
export default class MixinTiptapEditor<
  T extends { [index: string]: unknown } = Record<string, unknown>,
> extends Vue {
  public readonly node!: { attrs: T }
  public readonly updateAttrs!: (attrs?: T) => void
  public readonly editable!: boolean
  public readonly option!: Record<string, unknown>
  public readonly selected!: boolean
  public readonly view!: { editable: boolean; focused: boolean }

  private local = {
    editing: false,
  }

  public get attrs() {
    return this.node.attrs
  }

  public set attrs(data) {
    this.updateAttrs(data)
  }

  public get editing() {
    return this.view.editable ? this.$bags.tiptap.state.editing : false
  }

  public set editing(value) {
    this.local.editing = this.view.editable && value
    this.$bags.tiptap.state.editing = this.local.editing
  }

  destroyed() {
    this.editing = false
  }
}
