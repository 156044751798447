import { QueryOptions as Options } from 'apollo-client'
import { DocumentNode } from 'graphql'

export class QueryOptions<V = Record<string, unknown>> {
  query: DocumentNode

  constructor(query: DocumentNode) {
    this.query = query
  }

  variables(variables: V): V {
    return variables
  }

  getOptions(variables: V): Options {
    return {
      query: this.query,
      variables,
    }
  }
}
