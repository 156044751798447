import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesStoryCommentRemove {
  storyId: string
  commentId: string
}

export const remove = (
  variables: VariablesStoryCommentRemove,
): MutationOptions<{ storyCommentRemove: boolean }, VariablesStoryCommentRemove> => {
  return {
    mutation: gql`
      mutation ($storyId: ID!, $commentId: ID!) {
        storyCommentRemove(storyId: $storyId, commentId: $commentId)
      }
    `,
    variables,
  }
}
