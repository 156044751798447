import 'regenerator-runtime/runtime'
import './registerServiceWorker'
import '@/plugins'
import { apolloProvider } from '@/plugins/apollo'
import { i18n } from '@/plugins/i18n'
import router from '@/plugins/router'
import store from '@/plugins/store'
import { vuetify } from '@/plugins/vuetify'
import Vue from 'vue'
import App from './app.vue'
import bags from '@/bags'

const start = async () => {
  await bags.my.loadUserInfoWithoutIdTokenUpdate()
  bags.my.listenAuthStateChange()

  const app = new Vue({ i18n, router, store, vuetify, apolloProvider, render: (h) => h(App) })
  app.$mount('#app')
}

start()
