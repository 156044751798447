import { apolloClient, cookies } from '@/lib'
import { firebase } from '@/plugins/firebase'
import router from '@/plugins/router'
import store from '@/plugins/store'
import { ApolloClient, MutationOptions, QueryOptions } from 'apollo-client'
import Vue from 'vue'
import { i18n } from '@/plugins/i18n'

export default class Bag<State = undefined, Getter = undefined> {
  public static store = Bag.observable({
    get route() {
      return store.state.route
    },
  })
  state!: State
  getter!: Readonly<Getter>

  public static bags: surakh.bags
  public static router = router

  public get $bags() {
    return Bag.bags
  }

  public get $router() {
    return Bag.router
  }

  public get $i18n() {
    return i18n
  }

  static get firebase(): firebase.app.App {
    return firebase.app
  }

  get $firebase(): firebase.app.App {
    return Bag.firebase
  }

  static get auth(): firebase.auth.Auth {
    return firebase.auth
  }

  get $auth(): firebase.auth.Auth {
    return Bag.auth
  }

  protected static observable<T>(state: T): T {
    return Vue.observable(state)
  }

  protected observable<T>(state: T): T {
    return Vue.observable(state)
  }

  protected getState(state: State) {
    return this.observable(state)
  }

  protected getGetter(getterFunction: (state: State) => Readonly<Getter>) {
    const getter = getterFunction(this.state)
    return this.observable(getter)
  }

  public static cookies = {
    get(name: string): unknown {
      return cookies.get(name)
    },
    set(name: string, value?: string | boolean | number | undefined | null): void {
      cookies.set(name, value)
    },
    remove(name: string): void {
      cookies.remove(name)
    },
  }

  public static get apollo(): ApolloClient<unknown> {
    return apolloClient
  }

  public static async mutate<T, TVariables = undefined>(options: MutationOptions<T, TVariables>) {
    await this.bags.my.loadUserInfo()
    return this.apollo.mutate<T, TVariables>(options)
  }

  public static async query<T, TVariables = undefined>(options: QueryOptions<TVariables>) {
    await this.bags.my.loadUserInfo()
    return this.apollo.query<T, TVariables>(options)
  }

  public get $apollo() {
    return apolloClient
  }

  public async $mutate<T, TVariables = undefined>(options: MutationOptions<T, TVariables>) {
    await this.$bags.my.loadUserInfo()
    return this.$apollo.mutate<T, TVariables>(options)
  }

  public async $query<
    T = {
      myInfo: surakh.gql.types.MyInfo | null
      myRecentStories: string[] | null
      myPinnedStories: string[] | null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [query: string]: any
    },
    TVariables = undefined,
  >(options: QueryOptions<TVariables>, loadUserInfo = true) {
    if (loadUserInfo) await this.$bags.my.loadUserInfo()
    return this.$apollo.query<T, TVariables>(options)
  }
}
