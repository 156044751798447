import firebase from 'firebase/app'
import Bag from './Bag'
import consola from 'consola'
import * as schemas from '@/schemas'
import Vue from 'vue'

const state = Vue.observable({
  idToken: null as string | null,
})

export default class My extends Bag<
  {
    user: surakh.gql.types.MyInfo | null
    idToken: string | null | undefined
    firebaseUser: firebase.User | null
    isFirebaseAuthStateChanged: boolean
  },
  {
    uid: string | undefined
    isUser: boolean
    isUserNotVerified: boolean
    isGuest: boolean
    isTokenExpired: boolean
    isAuthenticated: boolean
    isNewUser: boolean
  }
> {
  public state = this.getState({
    user: null,
    firebaseUser: null,
    get idToken() {
      return (Bag.cookies.get('idToken') as string | null | undefined) ?? state.idToken
    },
    set idToken(value) {
      console.log('token', value)
      Bag.cookies.set('idToken', value)
      state.idToken = value
    },
    isFirebaseAuthStateChanged: false,
  })

  public getter = this.getGetter((state) => {
    return {
      get uid() {
        return state.user?.uid
      },
      get isGuest() {
        return !state.idToken && !this.isUser
      },
      get isTokenExpired() {
        return !!state.idToken && !this.isAuthenticated
      },
      get isAuthenticated() {
        return !!state.user
      },
      get isUser() {
        return !!state.user && state.user.emailVerified
      },
      get isUserNotVerified() {
        return !!state.user && !state.user.emailVerified
      },
      get isNewUser() {
        return !state.user && !!state.firebaseUser
      },
    }
  })

  private async resetApolloStore() {
    await this.$apollo.clearStore()
    await this.$apollo.resetStore()
  }

  private setUser(user: surakh.gql.types.MyInfo | null) {
    this.state.user = user
    if (this.state.user?.language) {
      this.$bags.default.state.language = this.state.user.language
    }
  }

  public async loadUserInfoWithoutIdTokenUpdate(refetch = true) {
    if (window.initialState.user !== undefined) {
      this.setUser(window.initialState.user)
      this.$apollo.writeQuery({
        ...schemas.queries.myInfo,
        data: {
          myInfo: window.initialState.user
            ? { ...window.initialState.user, __typename: 'GraphTypeMe' }
            : null,
        },
      })
    } else {
      this.setUser(this.state.idToken ? await this.$bags.queries.myInfo(refetch) : null)
    }
  }

  public async loadUserInfo(user?: firebase.User | null) {
    this.state.firebaseUser = user ?? Bag.auth?.currentUser
    const token = await this.state.firebaseUser?.getIdToken()
    const isTokenChanged = token !== undefined && this.state.idToken !== token
    if (isTokenChanged) {
      this.state.idToken = token
      await this.resetApolloStore()
    }

    this.setUser(this.state.firebaseUser ? await this.$bags.queries.myInfo(isTokenChanged) : null)
  }

  public async processSignIn(firebaseSignin: () => Promise<firebase.auth.UserCredential>) {
    let user: firebase.User | null = null
    try {
      user = (await firebaseSignin()).user
    } catch (error) {
      this.$bags.default.setSnackbar((error as Error).message, 10000)
      return
    }

    await this.loadUserInfo(user)

    if (this.getter.isUserNotVerified) {
      await this.$bags.my.state.firebaseUser?.sendEmailVerification()
      this.$bags.default.setSnackbar(this.$i18n.t('pleaseReCheckEmail').toString(), -1)
    } else if (this.getter.isNewUser) {
      await this.$router.push({ name: 'signupProvider' }).catch((err) => consola.log(err.message))
    }
  }

  public async signinWithEmailAndPassword(email: string, password: string) {
    return this.processSignIn(async () => this.$auth.signInWithEmailAndPassword(email, password))
  }

  public async signinWithEmailLink(email: string) {
    return this.processSignIn(() => this.$auth.signInWithEmailLink(email))
  }

  public async signinWithProvider(name: 'FacebookAuthProvider' | 'GoogleAuthProvider') {
    return this.processSignIn(async () => this.$auth.signInWithPopup(new firebase.auth[name]()))
  }

  public async sendSigninWithEmailLink(email: string) {
    try {
      await this.$auth.sendSignInLinkToEmail(email, {
        url: `${window.location.origin}/signin?email=${email}`,
        handleCodeInApp: true,
      })
      this.$bags.default.setSnackbar(this.$i18n.t('sentSigninEmail').toString(), 10000)
      return true
    } catch (error) {
      this.$bags.default.setSnackbar((error as Error).message, 10000)
      return false
    }
  }

  public async signout() {
    this.$router.push('/').catch((err) => consola.log(err.message))
    await this.$auth.signOut()
    this.state.idToken = null
    this.state.firebaseUser = null
    this.setUser(null)
    await this.resetApolloStore()
  }

  public async update(variables: surakh.gql.mutation.variables.UserUpdate) {
    this.setUser((await this.$bags.mutations.userUpdate(variables)) ?? null)
  }

  private async createUser(
    variables: surakh.gql.mutation.variables.UserCreate,
    user?: firebase.User | null,
  ) {
    try {
      if (user !== undefined) this.state.firebaseUser = user
      this.state.idToken = await this.state.firebaseUser?.getIdToken(true)
      if (!this.state.idToken) {
        return false
      }
      this.state.user = await this.$bags.mutations.userCreate(variables)
      if (!this.state.firebaseUser?.emailVerified) {
        await this.state.firebaseUser?.sendEmailVerification()
        this.$bags.default.setSnackbar(this.$i18n.t('pleaseCheckEmail').toString(), 10000)
        this.$router.replace({ name: 'index' }).catch((err) => consola.info(err.message))
      }
      return true
    } catch (error) {
      this.$bags.default.setSnackbar((error as Error).message, 10000)
      return false
    }
  }

  public async createUserWithEmailAndPassword(
    variables: surakh.gql.mutation.variables.UserCreate & { email: string; password: string },
  ) {
    try {
      const { email, password } = variables
      const { user } = await this.$auth.createUserWithEmailAndPassword(email, password!)
      return this.createUser(variables, user)
    } catch (error) {
      this.$bags.default.setSnackbar((error as Error).message, 10000)
      return false
    }
  }

  public async createWithProvider(variables: surakh.gql.mutation.variables.UserCreate) {
    try {
      return this.createUser(variables)
    } catch (error) {
      this.$bags.default.setSnackbar((error as Error).message, 10000)
      return false
    }
  }

  public async remove() {
    try {
      await this.$auth.currentUser?.delete()
      location.href = '/'
    } catch (error) {
      this.$bags.default.setSnackbar(this.$i18n.t('pleaseReLogin').toString(), 10000)
    }
  }

  public listenAuthStateChange = (onSuccess?: () => void) => {
    this.$auth.onAuthStateChanged(async (user) => {
      if (this.state.isFirebaseAuthStateChanged) {
        return
      }
      const previousIdToken = this.$bags.my.state.idToken
      await this.loadUserInfo(user)
      this.state.isFirebaseAuthStateChanged = true

      if (this.getter.isUser) {
        if (onSuccess) onSuccess()
      } else if (
        (previousIdToken && !this.$bags.my.state.idToken) ||
        this.$bags.my.getter.isUserNotVerified
      ) {
        await this.$bags.my.signout()
      }
    })
  }
}
