/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, Transaction } from 'prosemirror-state'
import { Node } from 'tiptap'

export default class TiptapNode extends Node {
  public commands({ type }: { type: any }) {
    return (attrs: any) => (state: EditorState, dispatch: (tr: Transaction) => void) => {
      const selection = state.selection
      const cursor = (selection as any).$cursor
      const position = cursor ? cursor.pos : selection.$to.pos
      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }
}
