import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesRemove {
  album: string
  image: string
}

export const remove = (
  variables: VariablesRemove,
): MutationOptions<{ imageRemove: string | null }, VariablesRemove> => {
  return {
    mutation: gql`
      mutation ($album: ID!, $image: String!) {
        imageRemove(album: $album, image: $image)
      }
    `,
    variables,
  }
}
