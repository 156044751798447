import Bag from './Bag'
import { i18n } from '@/plugins/i18n'
import mn from 'common/dayjs/mn'
import en from 'dayjs/locale/en'
import ja from 'dayjs/locale/ja'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
const dayJsLocales = { mn, en, ja }

export default class Default extends Bag<{
  snackbar: boolean
  language: string
  drawer: boolean
  snackbarText: string
  snackbarTimeout: number
  pageErrorCode: number | string | null
  readonly pageErrorMessage: string
  preventPageUnload: boolean
}> {
  state = this.getState({
    pageErrorCode: null,
    get pageErrorMessage() {
      if (this.pageErrorCode === null) {
        return ''
      }
      const pageErrorCode = [403, 404, 500, '403', '404', '500'].includes(this.pageErrorCode)
        ? this.pageErrorCode
        : 'other'
      return i18n.t(`pageErrors.${pageErrorCode}`).toString()
    },
    get snackbar() {
      return this.snackbarText !== ''
    },
    set snackbar(value) {
      if (value === false) {
        this.snackbarText = ''
        this.snackbarTimeout = 6000
      }
    },
    get language() {
      const language = Bag.cookies.get('language')
      return typeof language === 'string' && ['en', 'ja', 'mn'].includes(language) ? language : 'en'
    },
    set language(value: string) {
      if (['en', 'ja', 'mn'].includes(value)) {
        Bag.cookies.set('language', value)
        dayjs.locale(dayJsLocales[value as 'en' | 'ja' | 'mn'])
      }
    },
    drawer: false,
    snackbarText: '',
    snackbarTimeout: 6000,
    preventPageUnload: false,
  })

  $error(code: number) {
    this.state.pageErrorCode = code
  }

  setSnackbar(text: string, timeout = 6000) {
    this.state.snackbarTimeout = timeout
    this.state.snackbarText = text
  }
}
