export const story = (
  fields: (
    | 'id'
    | 'pageId'
    | 'authorId'
    | 'content'
    | 'createdAt'
    | 'draftId'
    | 'tags'
    | 'updatedAt'
    | 'visibility'
    | 'isAuthor'
    | 'isPinned'
    | 'isLiked'
    | 'likes'
    | 'image'
    | 'thumbnail'
    | 'title'
    | 'album'
    | 'views'
    | 'description'
    | 'seriesId'
    | 'images'
  )[] = [
    'id',
    'pageId',
    'authorId',
    'content',
    'createdAt',
    'draftId',
    'tags',
    'updatedAt',
    'visibility',
    'isAuthor',
    'isPinned',
    'likes',
    'isLiked',
    'image',
    'thumbnail',
    'views',
    'title',
    'album',
    'description',
    'seriesId',
    'images',
  ],
) => fields.join('\n')
