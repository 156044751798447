/* eslint-disable prettier/prettier */
/** This file is automatically generated. do not change */
import Index from '@/views/index.vue'

export default [
  {
    path: '/users/:userId/stories/recents',
    name: 'users-userId-stories-recents',
    component: () => import('@/views/users/_userId/stories/recents.vue'),
  },
  {
    path: '/users/:userId/stories/pins',
    name: 'users-userId-stories-pins',
    component: () => import('@/views/users/_userId/stories/pins.vue'),
  },
  {
    path: '/users/:userId/tags/:tagId',
    name: 'users-userId-tags-tagId',
    component: () => import('@/views/users/_userId/tags/_tagId.vue'),
  },
  {
    path: '/runantsrun/charity2020',
    name: 'runantsrun-charity2020',
    component: () => import('@/views/runantsrun/charity2020.vue'),
  },
  {
    path: '/stories/:storyId/edit',
    name: 'stories-storyId-edit',
    component: () => import('@/views/stories/_storyId/edit.vue'),
  },
  {
    path: '/users/:userId/profile',
    name: 'users-userId-profile',
    component: () => import('@/views/users/_userId/profile.vue'),
  },
  {
    path: '/users/:userId/stories',
    name: 'users-userId-stories',
    component: () => import('@/views/users/_userId/stories/index.vue'),
  },
  {
    path: '/users/:userId/series',
    name: 'users-userId-series',
    component: () => import('@/views/users/_userId/series/index.vue'),
  },
  {
    path: '/series/:seriesId',
    name: 'series-seriesId',
    component: () => import('@/views/series/_seriesId.vue'),
  },
  {
    path: '/stories/:storyId',
    name: 'stories-storyId',
    component: () => import('@/views/stories/_storyId/index.vue'),
  },
  {
    path: '/signupProvider',
    name: 'signupProvider',
    component: () => import('@/views/signupProvider.vue'),
  },
  {
    path: '/users/:userId',
    name: 'users-userId',
    component: () => import('@/views/users/_userId/index.vue'),
  },
  {
    path: '/tags/:tagId',
    name: 'tags-tagId',
    component: () => import('@/views/tags/_tagId.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
  },
  {
    path: '/series',
    name: 'series',
    component: () => import('@/views/series/index.vue'),
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/signin.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/signup.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/terms.vue'),
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/tags/index.vue'),
  },
  {
    path: '/',
    name: 'index',
    component: Index,
  }
]