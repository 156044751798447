import Vue from 'vue'
import VueClamp from 'vue-clamp'
import VueMeta from 'vue-meta'
import { Component } from 'vue-property-decorator'
import { apolloPlugin } from './apollo'
import { axiosPlugin } from './axios'
import { bagsPlugin } from './bags'
import { firebasePlugin } from './firebase'
import { formatNumberPlugin } from './formatNumber'
import { vuetifyPlugin } from './vuetify'

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

Vue.use(apolloPlugin)
Vue.use(axiosPlugin)
Vue.use(bagsPlugin)
Vue.use(firebasePlugin)
Vue.use(formatNumberPlugin)
Vue.use(VueMeta)
Vue.use(vuetifyPlugin)
Vue.component('VClamp', VueClamp)
Vue.config.productionTip = false
