import { locales } from 'common/i18n'
import Vue from 'vue'
import VueI18nPlugin from 'vue-i18n'
import { cookies } from '@/lib'

Vue.use(VueI18nPlugin)

export const i18n = new VueI18nPlugin({
  locale: (cookies.get('language') ?? 'en') as string,
  fallbackLocale: 'en',
  messages: locales,
})
