import command from './command'
import menu from './menu'

export interface InterfaceCommandItem {
  commandName: command
  activeName?: string
  activeIcon?: string
  icon: string
  group?: string
  params?: { level: number } | { rowsCount: number; colsCount: number; withHeaderRow: boolean }
}
export interface InterfaceCommandItems {
  [index: string]: InterfaceCommandItem
}

const commandMap: InterfaceCommandItems = {
  [menu.headingMedium]: {
    commandName: command.heading,
    activeName: command.heading,
    group: command.heading,
    icon: 'mdi-format-size',
    params: { level: 3 },
  },
  [menu.heading2]: {
    commandName: command.heading,
    activeName: command.heading,
    icon: 'mdi-format-header-2',
    group: command.heading,
    params: { level: 2 },
  },
  [menu.heading3]: {
    commandName: command.heading,
    activeName: command.heading,
    icon: 'mdi-format-header-3',
    group: command.heading,
    params: { level: 3 },
  },
  [menu.bold]: {
    commandName: command.bold,
    activeName: command.bold,
    icon: 'mdi-format-bold',
  },
  [menu.italic]: {
    commandName: command.italic,
    activeName: command.italic,
    icon: 'mdi-format-italic',
  },
  [menu.strikethrough]: {
    commandName: command.strikethrough,
    activeName: command.strikethrough,
    icon: '$vuetify.icons.strikethrough',
  },
  [menu.underline]: {
    commandName: command.underline,
    activeName: command.underline,
    icon: 'mdi-format-underline',
  },
  [menu.code]: {
    commandName: command.code,
    activeName: command.code,
    icon: 'mdi-code-brackets',
  },
  [menu.codeBlock]: {
    commandName: command.codeBlock,
    activeName: command.codeBlock,
    icon: 'mdi-code-tags',
  },
  [menu.link]: {
    commandName: command.link,
    activeName: command.link,
    icon: 'mdi-link-plus',
    activeIcon: 'mdi-link-off',
  },
  [menu.bulletList]: {
    commandName: command.bulletList,
    activeName: command.bulletList,
    group: 'list',
    icon: 'mdi-format-list-bulleted',
  },
  [menu.orderedList]: {
    commandName: command.orderedList,
    activeName: command.orderedList,
    group: 'list',
    icon: 'mdi-format-list-numbered',
  },
  [menu.blockQuote]: {
    commandName: command.blockQuote,
    activeName: command.blockQuote,
    icon: 'mdi-format-quote-close',
  },
  [menu.image]: {
    commandName: command.image,
    activeName: command.image,
    icon: 'mdi-image-plus',
  },
  [menu.images]: {
    commandName: command.images,
    activeName: command.images,
    icon: 'mdi-image-multiple',
  },
  [menu.video]: {
    commandName: command.video,
    activeName: command.video,
    icon: 'mdi-youtube',
  },
  [menu.markdown]: {
    commandName: command.markdown,
    activeName: command.markdown,
    icon: 'mdi-language-markdown',
  },
  [menu.embed]: {
    commandName: command.embed,
    activeName: command.embed,
    icon: 'mdi-language-html5',
  },
  [menu.createTable]: {
    commandName: command.createTable,
    icon: 'mdi-table-large-plus',
    params: { rowsCount: 3, colsCount: 3, withHeaderRow: false },
  },
  [menu.deleteTable]: {
    commandName: command.deleteTable,
    icon: 'mdi-table-large-remove',
  },
  [menu.addColumnBefore]: {
    commandName: command.addColumnBefore,
    icon: 'mdi-table-column-plus-before',
  },
  [menu.addColumnAfter]: {
    commandName: command.addColumnAfter,
    icon: 'mdi-table-column-plus-after',
  },
  [menu.deleteColumn]: {
    commandName: command.deleteColumn,
    icon: 'mdi-table-column-remove',
  },
  [menu.addRowBefore]: {
    commandName: command.addRowBefore,
    icon: 'mdi-table-row-plus-before',
  },
  [menu.addRowAfter]: {
    commandName: command.addRowAfter,
    icon: 'mdi-table-row-plus-after',
  },
  [menu.deleteRow]: {
    commandName: command.deleteRow,
    icon: 'mdi-table-row-remove',
  },
  [menu.toggleCellMerge]: {
    commandName: command.toggleCellMerge,
    icon: 'mdi-table-merge-cells',
  },
}

export default commandMap
