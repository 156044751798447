import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesAdd {
  album: string
  image: string
}

export const add = (
  variables: VariablesAdd,
): MutationOptions<{ imageAdd: string | null }, VariablesAdd> => {
  return {
    mutation: gql`
      mutation ($album: ID!, $image: String!) {
        imageAdd(album: $album, image: $image)
      }
    `,
    variables,
  }
}
