import { NavigationGuard } from 'vue-router'
import bags from '@/bags'

const redirectToMe: NavigationGuard = async (to, _from, next) => {
  await bags.my.loadUserInfoWithoutIdTokenUpdate()
  const userId = to.params.userId

  if (bags.my.getter.isUser && bags.my.getter.uid === userId) {
    return next({ name: 'users-userId-stories', params: { userId } })
  }

  await bags.user.loadUser(userId)
  if (bags.user.state.selectedUser) {
    return next()
  }

  return next(new Error('хэрэглэгч олдсонгүй'))
}

export default redirectToMe
