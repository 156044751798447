import { Vue, Component } from 'vue-property-decorator'

@Component<MixinStory>({
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
})
export class MixinStory extends Vue {
  readonly story!: surakh.gql.types.Story
}
