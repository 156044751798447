import { PluginObject } from 'vue'
import bags from '@/bags'
import Bag from '@/bags/Bag'
import { cookies } from '@/lib'
import { decode } from 'js-base64'

export const bagsPlugin: PluginObject<undefined> = {
  install: (Vue) => {
    Bag.bags = bags
    window.bags = bags

    Vue.bags = bags
    Vue.prototype.$bags = bags

    try {
      window.initialState = JSON.parse(decode(window.initialStateRaw))
    } catch (error) {
      window.initialState = { language: 'en', metas: { name: { title: '' }, property: {} } }
    }
    bags.default.state.pageErrorCode = window.initialState.error ?? null
    bags.default.state.language = window.initialState.language ?? cookies.get('language') ?? 'en'
  },
}
