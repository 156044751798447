import { Node, Plugin } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'

import { TiptapImage } from '../components'
import TiptapNode from './Node'
import ImageLoader from '@/utils/ImageLoader'

/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
const IMAGE_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

interface Attributes {
  src: string | null
  description: string | null
  aspectRatio: number | null
  wide: boolean | null
  contain: boolean | null
}

export default class Image extends TiptapNode {
  public get name() {
    return 'image'
  }

  public get view() {
    return TiptapImage
  }

  public get schema() {
    return {
      inline: true,
      attrs: {
        src: { default: null },
        description: { default: null },
        aspectRatio: { default: null },
        contain: { default: null },
        wide: { default: null },
      },
      group: 'inline',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: (dom: Element) => {
            const attrs = JSON.parse(dom.getAttribute('data-content') ?? '{}') as Attributes
            const src = dom.getAttribute('src')
            return Object.assign({}, attrs, { src })
          },
        },
      ],
      toDOM: (node: Node) => {
        const { src } = node.attrs as Attributes
        return ['img', { src, 'data-content': JSON.stringify(node.attrs) }]
      },
    }
  }

  public inputRules({ type }: { type: unknown }) {
    return [
      nodeInputRule(IMAGE_INPUT_REGEX, type, (match: string[]): Attributes => {
        const [, , src, description] = match

        return {
          src,
          description,
          aspectRatio: null,
          contain: null,
          wide: null,
        }
      }),
    ]
  }

  public get plugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event: Event): boolean {
              const load = async () => {
                event.preventDefault()
                const dragEvent = event as DragEvent
                const src = await ImageLoader.getDataURLFromDragEvent(dragEvent)

                if (src === null) {
                  return
                }

                const { schema } = view.state
                const coordinates = view.posAtCoords({
                  left: dragEvent.clientX,
                  top: dragEvent.clientY,
                })

                const node = schema.nodes.image.create({
                  src,
                  description: null,
                  aspectRatio: null,
                  contain: null,
                  wide: null,
                } as Attributes)

                const transaction = view.state.tr.insert(coordinates!.pos, node)
                view.dispatch(transaction)
              }
              load()
              return true
            },
          },
        },
      }),
    ]
  }
}
