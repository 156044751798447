import ja from './ja.json'
import mn from './mn.json'
import en from './en.json'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Locale extends Record<string, any> {
  pageTitle: {
    root: string
    index: string
    privacy: string
    terms: string
    profile: string
  }
  pageErrors: {
    '403': string
    '404': string
    '500': string
    other: string
  }
  searchByTags: string
  signIn: string
  signOut: string
  popularStories: string
  popularSeries: string
  popularTags: string
  myRecentTags: string
  antsTeam: string
  email: string
  displayName: string
  bio: string
  save: string
  unregister: string
  changePhoto: string
  maxLength: string
  minLength: string
  notEmpty: string
  edit: string
  delete: string
  back: string
  yes: string
  no: string
  signedOut: string
  createNewStory: string
  settings: string
  mySeries: string
  changeTheme: string
  accountSettings: string
}

export default { ja, mn, en } as { [language: string]: Locale }
