import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'

export interface VariablesStoryCommentUpdate {
  storyId: string
  commentId: string
  content: string
}

export const update = (
  variables: VariablesStoryCommentUpdate,
): MutationOptions<
  { storyCommentUpdate: surakh.gql.types.StoryComment },
  VariablesStoryCommentUpdate
> => {
  return {
    mutation: gql`
      mutation ($storyId: ID!, $commentId: ID!, $content: String!){
        storyCommentUpdate(storyId: $storyId, commentId: $commentId, content: $content) {
          ${fields.storyComment}
        }
      }
    `,
    variables,
  }
}
