import gql from 'graphql-tag'
import { QueryOptions } from 'apollo-client'

export interface VariablesImages {
  album: string
}

export const images = {
  queryOptions: {
    query: gql`
      query ($album: ID!) {
        images(album: $album)
      }
    `,
    variables: (variables: VariablesImages) => variables,
  },
  getQueryOptions(variables: VariablesImages): QueryOptions<VariablesImages> {
    return {
      query: this.queryOptions.query,
      variables,
    }
  },
}
