var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"storyHeadlines"},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('headings')))]),_vm._l((_vm.headerTagsAndTexts),function(ref,id){
var tag = ref.tag;
var text = ref.text;
return _c('v-list-item',{key:id,on:{"click":function($event){return _vm.value[id].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })}}},[_c(tag,{tag:"component"},[_vm._v(_vm._s(text.trim()))])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }