import { RouteConfig } from 'vue-router'
import { onlyMe, redirectToMe, userExists } from './middlewares'
import routers from 'common/routers'
import onlyGuest from './middlewares/onlyGuest'
import { STORY_VISIBILITY } from 'common/story/visibility'
import myStory from './middlewares/myStory'
import story from './middlewares/story'

const beforeEnters: { [name: string]: Partial<RouteConfig> } = {
  'stories-storyId': { beforeEnter: story },
  'stories-storyId-edit': { beforeEnter: myStory },
  'users-userId-series': { beforeEnter: userExists },
  'users-userId-stories-pins': { beforeEnter: onlyMe },
  'users-userId-stories-recents': { beforeEnter: onlyMe },
  'users-userId-stories-private': { beforeEnter: onlyMe },
  'users-userId-stories-hidden': { beforeEnter: onlyMe },
  'users-userId-stories-public': { beforeEnter: onlyMe },
  'users-userId': { beforeEnter: redirectToMe },
  'users-userId-profile': { beforeEnter: onlyMe },
  signin: { beforeEnter: onlyGuest },
  signup: { beforeEnter: onlyGuest },
  'users-userId-stories': {
    beforeEnter: onlyMe,
    props: (route) => ({
      authorId: route.params.userId,
      visibility: null,
    }),
  },
}

export default [
  ...routers.map(({ path, name, component }) => {
    return {
      path,
      name,
      component,
      ...beforeEnters[name],
    }
  }),
  {
    path: '/users/:userId/stories/private',
    name: 'users-userId-stories-private',
    component: () => import('@/views/users/_userId/stories/index.vue'),
    beforeEnter: onlyMe,
    props: (route) => ({
      authorId: route.params.userId,
      visibility: STORY_VISIBILITY.private,
    }),
  },
  {
    path: '/users/:userId/stories/hidden',
    name: 'users-userId-stories-hidden',
    component: () => import('@/views/users/_userId/stories/index.vue'),
    beforeEnter: onlyMe,
    props: (route) => ({
      authorId: route.params.userId,
      visibility: STORY_VISIBILITY.hidden,
    }),
  },
  {
    path: '/users/:userId/stories/public',
    name: 'users-userId-stories-public',
    component: () => import('@/views/users/_userId/stories/index.vue'),
    beforeEnter: onlyMe,
    props: (route) => ({
      authorId: route.params.userId,
      visibility: STORY_VISIBILITY.public,
    }),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/layouts/404.vue'),
  },
] as RouteConfig[]
