import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'

const { apolloClient } = createApolloClient({
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: process.env.VUE_APP_APOLLO_URI,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
})

export { apolloClient }
