import gql from 'graphql-tag'
import * as fields from '../fields'

export const myInfo: surakh.gql.query.option.MyInfo = {
  query: gql`
    query {
      myInfo {
        ${fields.myInfo}
      }
    }
  `,
}

export const myPinnedStories: surakh.gql.query.option.MyPinnedStories = {
  query: gql`
    query {
      myPinnedStories
    }
  `,
}

export const myRecentStories: surakh.gql.query.option.MyRecentStories = {
  query: gql`
    query {
      myRecentStories
    }
  `,
}

export const myRecentTags: surakh.gql.query.option.MyRecentTags = (variables) => {
  return {
    query: gql`
      query ($more: Boolean) {
        myRecentTags(more: $more)
      }
    `,
    variables,
  }
}

export const mySeries: surakh.gql.query.option.MySeries = {
  query: gql`
    query {
      mySeries {
        ${fields.series}
      }
    }
  `,
}
