import { NavigationGuard } from 'vue-router'
import bags from '@/bags'

const userExists: NavigationGuard = async (to, _from, next) => {
  await bags.user.loadUser(to.params.userId)
  if (bags.user.state.selectedUser) {
    next()
  } else {
    next(new Error('хэрэглэгч олдсонгүй'))
  }
}

export default userExists
