import gql from 'graphql-tag'
import { QueryOptions } from './QueryOptions'
import * as fields from '@/schemas/fields'

export const userSeries = new QueryOptions<surakh.Schema.User.VariablesUserSeries>(gql`
  query($uid: ID!) {
    userSeries(uid: $uid) {
      ${fields.series}
    }
  }
`)

export const userByUid = new QueryOptions<surakh.Schema.User.VariablesUserByUid>(gql`
  query($uid: ID!) {
    userByUid(uid: $uid) {
      ${fields.user}
    }
  }
`)

export const userByEmail = new QueryOptions<surakh.Schema.User.VariablesUserByEmail>(gql`
  query($email: String!) {
    userByEmail(email: $email) {
      ${fields.user}
    }
  }
`)
