import gql from 'graphql-tag'
import { QueryOptions } from 'apollo-client'

export interface VariablesUnsplashImages {
  keyword: string
  perPage: number
  page: number
}

export const unsplashImages = (
  variables: VariablesUnsplashImages,
): QueryOptions<VariablesUnsplashImages> => {
  return {
    query: gql`
      query ($keyword: String!, $page: Int, $perPage: Int) {
        unsplashImages(keyword: $keyword, page: $page, perPage: $perPage) {
          total_pages
          results {
            urls {
              regular
              small
              thumb
            }
            user {
              name
              username
            }
            links
          }
        }
      }
    `,
    variables,
  }
}
