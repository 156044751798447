enum EnumImageThumbnail {
  smallSquare = 's',
  bigSquare = 'b',
  smallThumbnail = 't',
  mediumThumbnail = 'm',
  largeThumbnail = 'l',
  hugeThumbnail = 'h',
}

export const getThumbnail = (url: string | undefined | null, thumbnail: EnumImageThumbnail) => {
  if (!url) {
    return undefined
  }
  if (/^https:\/\/i\.imgur\.com/.test(url)) {
    return url.replace(/(^https:\/\/i\.imgur\.com\/.+)(\.\w+)/, `$1${thumbnail}$2`)
  }
  return url
}

export const getSmallSquare = (url?: string | null) => {
  return getThumbnail(url, EnumImageThumbnail.smallSquare)
}

export const getBigSquare = (url?: string | null) => {
  return getThumbnail(url, EnumImageThumbnail.bigSquare)
}

export const getSmallThumbnail = (url?: string | null) => {
  return getThumbnail(url, EnumImageThumbnail.smallThumbnail)
}

export const getMediumThumbnail = (url?: string | null) => {
  return getThumbnail(url, EnumImageThumbnail.mediumThumbnail)
}

export const getLargeThumbnail = (url?: string | null) => {
  return getThumbnail(url, EnumImageThumbnail.largeThumbnail)
}

export const getHugeThumbnail = (url?: string | null) => {
  return getThumbnail(url, EnumImageThumbnail.hugeThumbnail)
}
