import PasswordValidator from 'password-validator'

const passwordValidator = new PasswordValidator()
passwordValidator
  .is()
  .min(8)
  .is()
  .max(20)
  .has()
  .digits(1)
  .has()
  .lowercase(1)
  .has()
  .uppercase(1)
  .has()
  .not()
  .spaces()

export default passwordValidator
