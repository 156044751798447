import gql from 'graphql-tag'
import { QueryOptions } from 'apollo-client'
import * as fields from '@/schemas/fields'

export interface VariablesStory {
  storyId: string
}

export const story = {
  queryOptions: {
    query: gql`
      query($storyId: ID!) {
        story(storyId: $storyId) {
          ${fields.story()}
        }
      }
    `,
    variables: (variables: VariablesStory) => variables,
  },
  getQueryOptions(variables: VariablesStory): QueryOptions<VariablesStory> {
    return {
      query: this.queryOptions.query,
      variables,
    }
  },
}
