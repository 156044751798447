import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'

export interface VariablesLike {
  storyId: string
  like: boolean
}

export const like = (
  variables: VariablesLike,
): MutationOptions<{ storyLike: surakh.gql.types.Story }, VariablesLike> => {
  return {
    mutation: gql`
      mutation($storyId: ID!, $like: Boolean!) {
        storyLike(storyId: $storyId, like: $like) {
          ${fields.story()}
        }
      }
    `,
    variables,
  }
}
