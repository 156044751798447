import { Node } from 'tiptap'
import { TiptapVideo } from '../components'
import TiptapNode from './Node'

export default class Video extends TiptapNode {
  public get name() {
    return 'video'
  }

  public get view() {
    return TiptapVideo
  }

  public get schema() {
    return {
      attrs: {
        src: { default: null },
      },
      group: 'block',
      selectable: false,
      draggable: false,
      parseDOM: [
        {
          tag: 'iframe',
          getAttrs: (dom: Element) => ({
            src: dom.getAttribute('src'),
          }),
        },
      ],
      toDOM: (node: Node) => [
        'iframe',
        {
          src: node.attrs.src,
          frameborder: 0,
          allowfullscreen: 'true',
        },
      ],
    }
  }
}
