import gql from 'graphql-tag'
import { QueryOptions } from 'apollo-client'
import * as fields from '@/schemas/fields'
import { STORY_VISIBILITY } from 'common/story/visibility'

export interface VariablesStories {
  startAfter?: number | null
  visibility?: STORY_VISIBILITY | null
  authorId?: string | null
  tag?: string | null
  seriesId?: string | null
}

export const stories = {
  queryOptions: {
    query: gql`
      query($startAfter: Float, $visibility: GraphEnumStoryVisibility, $authorId: ID, $tag: String, $seriesId: String) {
        stories(startAfter: $startAfter, visibility: $visibility, authorId: $authorId, tag: $tag, seriesId: $seriesId) {
          stories {
            ${fields.story(['id'])}
          }
          hasMore
          startAfter
        }
      }
    `,
    variables: (variables: VariablesStories) => variables,
  },
  getQueryOptions(variables: VariablesStories): QueryOptions<VariablesStories> {
    return {
      query: this.queryOptions.query,
      variables,
    }
  },
}
