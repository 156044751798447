import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'

export interface VariablesRemove {
  seriesId: string
}

export const remove = (
  variables: VariablesRemove,
): MutationOptions<{ seriesRemove: boolean | null }, VariablesRemove> => {
  return {
    mutation: gql`
      mutation ($seriesId: ID!) {
        seriesRemove(seriesId: $seriesId)
      }
    `,
    variables,
  }
}
