import { Plugin, Node } from 'tiptap'
import { TiptapImages } from '../components'
import TiptapNode from './Node'
import ImageLoader from '@/utils/ImageLoader'

interface Attributes {
  images: string[]
  displayType: 'slide' | 'collection'
}

export default class Images extends TiptapNode {
  public get name() {
    return 'images'
  }

  public get view() {
    return TiptapImages
  }

  public get schema() {
    return {
      attrs: {
        images: { default: [] as string[] },
        displayType: { default: 'slide' as 'slide' | 'collection' },
      },
      group: 'block',
      selectable: false,
      draggable: false,
      parseDOM: [
        {
          tag: '[data-type="images"]',
          getAttrs: (dom: Element): Attributes => {
            return JSON.parse(dom.getAttribute('data-content') ?? '{}')
          },
        },
      ],
      toDOM: (node: Node) => {
        return [
          'div',
          {
            'data-type': 'images',
            'data-content': JSON.stringify(node.attrs),
          },
        ]
      },
    }
  }

  public get plugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event: Event): boolean {
              const load = async () => {
                event.preventDefault()
                const dragEvent = event as DragEvent
                const images = await ImageLoader.getDataURLsFromDragEvent(dragEvent)
                if (images.length === 0) {
                  return
                }
                const { schema } = view.state
                const coordinates = view.posAtCoords({
                  left: dragEvent.clientX,
                  top: dragEvent.clientY,
                })

                const node = schema.nodes.image.create({
                  images,
                  displayType: 'slide',
                } as Attributes)

                const transaction = view.state.tr.insert(coordinates!.pos, node)
                view.dispatch(transaction)
              }
              load()
              return true
            },
          },
        },
      }),
    ]
  }
}
