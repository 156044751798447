import { MutationOptions } from 'apollo-client'
import gql from 'graphql-tag'
import * as fields from '@/schemas/fields'
import { InterfaceSeries } from '@/types'

export interface VariablesCreate {
  name: string
  description: string
  editors: string[]
  image: string | null
}

export const create = (
  variables: VariablesCreate,
): MutationOptions<{ seriesCreate: InterfaceSeries | null }, VariablesCreate> => {
  return {
    mutation: gql`
      mutation($name: String!, $description: String!, $editors: [String!]!, $image: String) {
        seriesCreate(name: $name, description: $description, editors: $editors, image: $image) {
          ${fields.series}
        }
      }
    `,
    variables,
  }
}
