import gql from 'graphql-tag'
import { QueryOptions } from 'apollo-client'

export const popularTags: QueryOptions = {
  query: gql`
    query {
      popularTags
    }
  `,
}
