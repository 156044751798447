import { NavigationGuard } from 'vue-router'
import bags from '@/bags'

const onlyMe: NavigationGuard = async (to, _from, next) => {
  const userId = to.params.userId

  if (bags.my.getter.uid === userId) {
    return next()
  }

  await bags.user.loadUser(userId)
  if (bags.user.state.selectedUser) {
    return next({ name: 'users-userId', params: { userId } })
  }

  return next(new Error('Нэвтрэх эрх байхгүй байна'))
}

export default onlyMe
